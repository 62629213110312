import { CircularProgress } from "@mui/material"
import { BotaoRedondo } from "./BotaoRedondo"
import { ReactComponent as Plus } from "../images/svgs/plus.svg";
import { ReactComponent as Check } from "../images/svgs/check-mark.svg";
import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";

export const CardAndTextContent = ({ item, duracao }) => {
    const { user, env, handleMatricula } = useAuth();
    const [iconeDaMihaLista, setIconeDaMinhaLista] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        const headers = { 'x-access-token': user.access_token };
        fetch(env.nodeUrl + "enrollments?canvas_id=" + item.canvas_id + "&user_id="+user.user.id, { headers })
            .then(resposta => resposta.json())
            .then(resposta => {
                const { enrollment } = resposta.metadados;
                setIconeDaMinhaLista(enrollment || false);
                setLoading(false);
            })
            .catch(e => setLoading(false));
    }, []);

    return <div className="card-do-material">
    <div style={{ backgroundImage: `url(${item.thumbnail})` }} className="imagens-do-material" onClick={() => handleMatricula(item, 'assistir')}>
        <img src={item.custom_title} />
        <span children={duracao} />
    </div>
    <div className="informacoes-do-material">
        <div>
            <BotaoRedondo
                identifier="matricula"
                texto={iconeDaMihaLista ? "Em Minha lista" : "Adicionar à Minha lista"}
                icone={(loading && <CircularProgress thickness={1.7} />) || (iconeDaMihaLista ? <Check /> : <Plus />)}
                item={item}
                setIconeDaMinhaLista={setIconeDaMinhaLista}
            />
        </div>
        <p>{item.description}</p>
    </div>
</div>
}
