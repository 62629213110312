import { CircularProgress } from "@mui/material";
import { ReactComponent as Legenda } from "../images/svgs/legenda.svg";
import { ReactComponent as ArrowNext } from "../images/svgs/arrow-next.svg";
import { ReactComponent as ArrowPrevious } from "../images/svgs/arrow-previous.svg";
import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";

export const PopUpTriggerIcon = ({ conteudo, identifier, setPageNumber, pageNumber }) => {
    const { handleMatricula } = useAuth();
    const [showPopUp, setShowPopUp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [icon, setIcon] = useState(false);
    const [text, setText] = useState(false);

    useEffect(() => {
        switch (identifier) {
            case "material-complementar":
                setIcon(<Legenda />);
                setText("Acompanhe o Slide da Aula");
                break;

            case "previous-video":
                setText("Vídeo Anterior");
                setIcon(<ArrowPrevious />);
                break;
            
            case "next-video":
                setText("Próximo vídeo");
                setIcon(<ArrowNext />);
                break;

            case "next-page":
                setText("Próxima página");
                setIcon(<ArrowNext />);
                break;

            case "previous-page":
                setText("Página Anterior");
                setIcon(<ArrowPrevious />);
                break;
        
            default:
                break;
        }
    }, []);

    return <div className="pop-up-icon-trigger">
        <div
            {...showPopUp && { show: "block" }}
            className="pop-up-of-the-icon"
            onClick={() => {
                setLoading(true);
                if (conteudo) handleMatricula(conteudo, "assistir", (identifier == "material-complementar") && "blank");
                else if (identifier == "material-complementar") {} 
                else setPageNumber(identifier == "next-page" ? pageNumber + 1 : pageNumber - 1);
                setTimeout(() => setLoading(false), 1200);
            }}
            onMouseOver={() => setShowPopUp(true)}
            onMouseLeave={() => setShowPopUp(false)}
            children={<div
                className="pop-up-content"
                children={loading ? <CircularProgress /> : text}
            />}
        />
        <div
            onMouseOver={() => setShowPopUp(true)}
            onMouseLeave={() => setShowPopUp(false)}
            onClick={() => {
                setLoading(true);
                if (conteudo) handleMatricula(conteudo, "assistir", (identifier == "material-complementar") && "blank");
                else if (identifier == "material-complementar") {} 
                else setPageNumber(identifier == "next-page" ? pageNumber + 1 : pageNumber - 1);
                setTimeout(() => setLoading(false), 1200);
            }}
            children={icon}
        />
    </div>
}
