import { BotaoRedondo } from "./BotaoRedondo"
import { ReactComponent as Like } from "../images/svgs/like.svg";
import { ReactComponent as Deslike } from "../images/svgs/deslike.svg";
import { ReactComponent as Amei } from "../images/svgs/amei.svg";
import { ReactComponent as LikeAtivo } from "../images/svgs/like-ativo.svg";
import { ReactComponent as DeslikeAtivo } from "../images/svgs/deslike-ativo.svg";
import { ReactComponent as AmeiAtivo } from "../images/svgs/amei-ativo.svg";
import { useEffect, useState } from "react";

export const BotoesDeAvaliacao = ({ iconeDeAvaliacao, setIconeDeAvaliacao, item }) => {
    const [popUpAvaliacao, setPopUpAvaliacao] = useState(false);
    const arrayDeBotoes = [
        {
            tipo: "dislike",
            texto: "Não é pra mim",
            icone: <Deslike />,
            ativo: <DeslikeAtivo />,
        },
        {
            tipo: "like",
            texto: "Gostei",
            icone: <Like />,
            ativo: <LikeAtivo />,
        },
        {
            tipo: "super-like",
            texto: "Amei!",
            icone: <Amei />,
            ativo: <AmeiAtivo />,
        },
    ]

    return <>
        {item && <div
            className="botoes-de-avaliacao"
            onMouseOver={() => setPopUpAvaliacao(true)}
            onMouseLeave={() => setTimeout(() =>setPopUpAvaliacao(false), 300)}
        >
            <BotaoRedondo
                identifier="avaliacao"
                icone={arrayDeBotoes.filter(botao => botao.tipo == iconeDeAvaliacao)[0]?.ativo || arrayDeBotoes[1].icone}
                item={item}
            />
            {popUpAvaliacao && <div
                className="pop-up-interacoes"
                children={arrayDeBotoes.map(({ tipo, texto, ativo, icone }, index) => <BotaoRedondo
                    key={index}
                    identifier="avaliacao"
                    icone={iconeDeAvaliacao == tipo ? arrayDeBotoes.filter(botao => botao.tipo == iconeDeAvaliacao)[0]?.ativo || arrayDeBotoes[1].icone : icone}
                    texto={iconeDeAvaliacao == tipo ? "Avaliado" : texto}
                    setIconeDeAvaliacao={setIconeDeAvaliacao}
                    tipo={tipo}
                    item={item}
                />)}
            />}
        </div>}
    </>
};
