import { useEffect, useState } from "react"
import { useAuth } from "../hooks/useAuth";
import { CardContent } from "../components/CardContent";
import { Loader } from "../components/Loader";
import { CircularProgress } from "@mui/material";
import { Footer } from "../components/Footer";

export const Conteudos = () => {
    const { env, user, hideAppBar, setHideAppBar } = useAuth();
    const [conteudos, setConteudos] = useState([]);
    const [requestedPage, setRequestedPage] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const headers = { 'x-access-token': user.access_token };
        fetch(env.nodeUrl + "conteudos?page=" + requestedPage + "&query=todos", { headers })
            .then(resposta => resposta.ok
                ? resposta.json()
                : setLoading(false))
            .then(resposta => {
                setLoading(false);
                const { contents } = resposta.metadados;
                if (contents) setConteudos([
                    ...conteudos,
                    ...contents
                ]);
            })
            .catch(e => setLoading(false));
    }, [requestedPage]);

    useEffect(() => {
        if (!hideAppBar) setHideAppBar(false);
        if (!user) return;

    }, []);
    
    const handleScroll = (e) => {
        const { scrollHeight, clientHeight, scrollTop } = e.target;

        if (loading) return;
        if (!scrollTop) return;
        if (scrollTop == scrollHeight - clientHeight) setRequestedPage(requestedPage + 1);
    }

    return <div
        onScroll={handleScroll}
        className="container-conteudos"
        children={<>
            <h1 children="Todos conteúdos" />
            <div
                className="conteudos"
                children={<>
                    {conteudos.length
                        ? conteudos.map(conteudo => <CardContent key={conteudo.id} item={conteudo} />)
                        : <Loader />}
                    {(conteudos.length && loading) ? <div className="container-loading" children={<CircularProgress thickness={1.7} />} /> : <></>}
                </>
                }
            />
            <Footer />
        </>}
    />
}
