import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowBack } from "../images/svgs/arrow-back.svg";

export const PopUpBackButton = ({ lastMovement }) => {
    const navigate = useNavigate();

    return <div
        {...lastMovement}
        className="pop-up-back-button"
        children={<ArrowBack onClick={() => navigate(-1)} />}
    />
}
