import { useEffect } from "react";
import { Link } from "react-router-dom"
import { useAuth } from "../hooks/useAuth";

export const RouteLink = ({url, text, linkAtivo, setLinkAtivo}) => {
    const { setResultados, setTextoDoInputDeBusca } = useAuth();
    useEffect(() => {
        if (window.location.pathname == url) setLinkAtivo(url)
    }, []);

    const handleClick = () => {
        setResultados([]);
        setTextoDoInputDeBusca("");
        setLinkAtivo(url);
    }

    return linkAtivo == url
        ? <span children={text} />
        : <Link to={url} children={text} onClick={handleClick} />
}
