import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { Slider } from "../components/Slider";
import { Box } from "@mui/system";
import { ReactComponent as Play } from "../images/svgs/play.svg";
import { ReactComponent as Info } from "../images/svgs/info.svg";
import { Loader } from "../components/Loader";
import { BotaoArredondado } from "../components/BotaoArredondado";
import { CircularProgress } from "@mui/material";
import { Footer } from "../components/Footer";

export const Inicio = () => {
    const { user, logout, env, hideAppBar, setHideAppBar, setUser } = useAuth();
    const [cursoEmDestaque, setCursoEmDestaque] = useState([]);
    const [tags, setTags] = useState([]);
    const [requestedPage, setRequestedPage] = useState(0);
    const [todosResultadosForamExibidos, setTodosResultadosForamExibidos] = useState(false);
    const [loading, setLoading] = useState(false);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    const handleScroll = (e) => {
        const { scrollHeight, clientHeight, scrollTop } = e.target;

        if (loading) return;
        if (!scrollTop) return;
        if (scrollTop == scrollHeight - clientHeight) setRequestedPage(requestedPage + 1);
    }

    useEffect(() => {
        if (!user) return;
        if (!env) return;

        fetch(env.nodeUrl + "refresh_token", {
            headers: {
                'Content-Type': 'application/json',
                'x-refresh-token': user.refresh_token,
                'hostname': env.hostname,
                'key': env.key,
            }
        })
        .then(resposta => resposta.ok ? resposta.json() : false)
        .then(dadosDoUsuario => {
            if (!dadosDoUsuario) return;

            setUser({
                ...dadosDoUsuario,
                refresh_token: user.refresh_token
            });
        });
    }, []);

    useEffect(() => {
        if (!hideAppBar) setHideAppBar(false);
        if (!user) return;

        window.addEventListener('resize', (e) => setInnerWidth(e.target.innerWidth));

        const headers = { 'x-access-token': user.access_token };
        if (cursoEmDestaque.id) return;

        fetch(env.nodeUrl + "conteudos/destaque?user_id=" + user.user.id + "&canvas_ids=[1007]", { headers })
            .then(resposta => {
                if (resposta.ok) return resposta.json();
                if (resposta.status == 500) logout();
            })
            .then(resposta => {
                const { contents } = resposta.metadados;
                const [conteudo] = contents;
                if (conteudo) setCursoEmDestaque(conteudo);
            })
            .catch(e => console.log(e))
    }, [user]);

    useEffect(() => {
        if (!user) return;
        if (todosResultadosForamExibidos) return;

        setLoading(true);
        const headers = { 'x-access-token': user.access_token };
        fetch(env.nodeUrl + "tags?page=" + requestedPage, { headers })
            .then(resposta => {
                if (resposta.ok) return resposta.json();
                if (resposta.status == 500) logout();
            })
            .then(resposta => {
                const { tags: newTags } = resposta.metadados;
                setLoading(false);

                if (!newTags.length) return setTodosResultadosForamExibidos(true);

                setTags([
                    ...tags,
                    ...newTags
                ]);
            });
    }, [requestedPage]);

    return <>
        {cursoEmDestaque.id ? <div className="container-inicio" onScroll={handleScroll}>
            <Box className="hero-header">
                <div
                    className="thumbnail-do-destaque"
                    style={{ backgroundImage: `linear-gradient(-90deg, transparent 0%, #141414 150%), linear-gradient(transparent 60%, #141414 90%), url(${cursoEmDestaque.thumbnail || cursoEmDestaque.image_download_url})` }}
                />
                <div className="info-do-destaque">
                    <img className="titulo-estilizado" src={cursoEmDestaque.custom_title} />
                    <div className="descricao">
                        {cursoEmDestaque.description}
                    </div>
                    <div className="botoes">
                        <BotaoArredondado
                            tipo="branco"
                            identifier="assistir"
                            icone={<Play />}
                            texto="Assistir"
                            item={cursoEmDestaque}
                        />
                        <BotaoArredondado
                            identifier="mais-info"
                            icone={<Info />}
                            texto="Mais informações"
                            item={cursoEmDestaque}
                        />
                    </div>
                </div>
            </Box>
            <div className="container-sliders">
                <Slider titulo="Destacados" innerWidth={innerWidth} endpoint="aulas" query="todas" />
                <Slider titulo="Vídeos" innerWidth={innerWidth} endpoint="conteudos" query="videos" />
                <Slider titulo="Minha lista" innerWidth={innerWidth} endpoint="conteudos/minha-lista" />
                {!!tags.length && tags.map(tag => (
                    <Slider
                        key={tag.id}
                        titulo={tag.name}
                        innerWidth={innerWidth}
                        endpoint="conteudos/busca"
                    />
                ))}
                {loading && <CircularProgress thickness={1.7} />}
                <Footer />
            </div>
        </div> : <Loader />}
    </>
};
