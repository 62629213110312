export const MoreDetails = ({ array, texto, showAll = false }) => {

    return array?.length
        ? <p>
            <span children={`${texto}: `} />
            {
                texto == 'Tipo'
                    ? array[1]
                    : array
                        .map((item, index) => {
                            if (!showAll && index > 3) return false; 
                            return item.name
                        })
                        .filter(Boolean)
                        .join(", ")
            }
            {(!showAll && array.length > 3) && <i>, <a href="#container-sobre-conteudo">mais</a></i>}
        </p>
        : <></>
}
