import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { useAuth } from "../hooks/useAuth";
import { ReactComponent as Play } from "../images/svgs/play.svg";
import { ReactComponent as Pause } from "../images/svgs/pause.svg";
import VolumeBaixo from "../images/svgs/volume-baixo.svg";
import VolumeAlto from "../images/svgs/volume-alto.svg";
import SemVolume from "../images/svgs/sem-volume.svg";
import Volume from "../images/svgs/volume.svg";
import { Loader } from "../components/Loader";
import { Slider } from "@mui/material";
import { PdfPlayer } from "./PdfPlayer";
import { MediadeliveryPlayer } from "../components/MediadeliveryPlayer";
import { YoutubePlayer } from "../components/YoutubePlayer";
import { ZoomIn, ZoomOut } from "@mui/icons-material";
import { PopUpBackButton } from "../components/PopUpBackButton";
import { PopUpTriggerIcon } from "../components/PopUpTriggerIcon";
import { PopUpPages } from "../components/PopUpPages";

export const Conteudo = () => {
    const { conteudoId } = useParams();
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState(false);
    const [lastMovement, setLastMovement] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checkpoints, setCheckpoints] = useState([]);

    const { user, setOpenModalTransicao, setHideAppBar, env } = useAuth();
    const [duracaoFormatada, setDuracaoFormatada] = useState(false);
    const [popUpRightIcons, setPopUpRightIcons] = useState([]);
    const [conteudo, setConteudo] = useState(null);

    const [player, setPlayer] = useState(null);
    const [showBarraDeVolume, setShowBarraDeVolume] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(100);
    const [volumeIcon, setVolumeIcon] = useState(Volume);
    const [progresso, setProgresso] = useState(false);
    const [barraDeProgresso, setBarraDeProgresso] = useState(0);

    const [numPages, setNumPages] = useState(false);
    const [popUpOutlinePages, setPopUpOutlinePages] = useState(false);
    const [popUpOutlinePagesTrigger, setPopUpOutlinePagesTrigger] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);

    // Functions ------------------------------------------------------------

    const registraConclusao = () => {
        const config = {
            method: "POST",
            body: new URLSearchParams({
                'userId': user.user.id,
                'tipo': 'concluido'
            })
        };
        fetch(env.nodeUrl + "conteudos/" + conteudoId + "/registrar-interacao", config);
    }

    const formataDuracao = () => {
        if (!player?.current) return;

        let duracaoCaptada = conteudo.is.youtube
            ? player?.current.getDuration()
            : player?.current.duration;

        if (isNaN(duracaoCaptada)) duracaoCaptada = 0;

        let string = "";
        const duracao = duracaoCaptada - progresso;
        const horas = Math.floor(duracao / 3600);
        const minutos = Math.floor((duracao - (horas * 3600)) / 60);
        const segundos = Math.floor(duracao - (horas * 3600) - (minutos * 60));

        if (horas > 0) string += horas + ":";
        string += (minutos < 10 ? ("0" + minutos) : minutos) + ":";
        string += (segundos < 10 ? ("0" + segundos) : segundos);
        setDuracaoFormatada(string);
    }

    const getPorcentagem = (segundos) => {
        if (!player?.current) return;

        const duracaoCaptada = conteudo.is?.youtube
            ? player.current.getDuration()
            : player.current.duration;

        const total = conteudo.moreInfo.total_time || parseInt(duracaoCaptada);

        return segundos * 100 / total;
    };

    const salvaProgresso = (segundos, page = false) => {
        let aCadaSegundos;
        let fimDoCiclo;

        if (segundos) {
            aCadaSegundos = 10;
            fimDoCiclo = parseInt(segundos % aCadaSegundos);
        }

        if (fimDoCiclo == 0 || page) {
            
            let duracaoCaptada = 0;
            if (player?.current) {
                duracaoCaptada = conteudo?.is.youtube
                    ? player.current.getDuration()
                    : player.current.duration;
            } else {
                if (!numPages) return;
                if (!page) page = 1;

                duracaoCaptada = numPages;
                segundos = page;
            }

            const partes = 10;
            const fracao = duracaoCaptada / partes;
            let fracaoAtual = false;
    
            for (let i = 0; i <= partes; i++) {
                if (segundos >= (fracao * i)) fracaoAtual = fracao * i;
            }

            const [checkpoint] = checkpoints.filter(checkpoint => checkpoint.spent_time >= fracaoAtual && checkpoint.spent_time <= (fracaoAtual + fracao));

            fetch(env.nodeUrl + "conteudos/" + conteudo.moreInfo.id + "/tempo-consumido", {
                method: "POST",
                body: new URLSearchParams({
                    'userId': user.user.id,
                    'quantidade': page || parseInt(progresso),
                    'id': checkpoint?.id
                })
            })
            .then(resposta => resposta.ok
                ? resposta.json()
                : false
            )
            .then(resposta => {
                const { times } = resposta;
                if (times) setCheckpoints(times);
            });
        }
    }

    const mudaVolume = (e, newValue) => {
        if (conteudo.is.mediadelivery) player.current.volume = newValue / 100;
        setVolume(newValue);
        if (newValue == 0) return setVolumeIcon(SemVolume);
        if (newValue < 33) return setVolumeIcon(VolumeBaixo);
        if (newValue < 66) return setVolumeIcon(Volume);
        setVolumeIcon(VolumeAlto);
    }

    const mudaProgresso = (e, porcentagem) => {
        let duracaoCaptada = conteudo.is.youtube
            ? player.current.getDuration()
            : player.current.duration;

        const segundos = duracaoCaptada * porcentagem / 100;
        if (conteudo.is.youtube) player.current.seekTo(segundos);
        if (conteudo.is.mediadelivery) player.current.currentTime = segundos;
        setBarraDeProgresso(porcentagem);
    }

    const listaDeCheckPoints = (conteudo) => {
        let duracaoCaptada = 0;

        if (player?.current) {
            duracaoCaptada = conteudo?.is.youtube
                ? player.current.getDuration()
                : player.current.duration;
        } else {
            duracaoCaptada = numPages
        }

        const partes = 10;
        const fracao = duracaoCaptada / partes;
        const lista = [];

        for (let i = 1; i <= partes; i++) {
            const [check] = checkpoints.filter(checkpoint => checkpoint.spent_time >= Math.ceil(fracao * (i - 1)) && checkpoint.spent_time <= Math.ceil(fracao * i));
            lista.push(<span
                className={["fracao", check && "check"].join(" ")}
                key={i}
                children={<>
                    <p>Id: {check?.id}</p>
                    <p>Tempo Registrada: {check?.spent_time}</p>
                    <p>Segundos: {fracao * i}s</p>
                </>}
            />);
        }
        return lista;
    }


    // Effects (em ordem de execução) -----------------------------------------------------------------------
    
    useEffect(() => {
        setHideAppBar(true);
        setOpenModalTransicao(false);
        setCurrentTime(currentTime + 1);
        setLoading(true);
        
        if (!conteudoId) return;
        
        const headers = { 'x-access-token': user.access_token };
        
        fetch(env.nodeUrl + "conteudos/" + conteudoId, { headers })
            .then(r => r.ok ? r.json() : navigate("/browse"))
            .then(r => setConteudo(r))
            .catch(e => navigate("/browse"));

        fetch(env.nodeUrl + "conteudos/" + conteudoId + "/registrar-visualizacao", {
            method: "POST",
            body: new URLSearchParams({ 'userId': user.user.id })
        });

        return () => {
            setPlayer(false);
            setPopUpRightIcons([]);
            setBarraDeProgresso(0);
            setDuracaoFormatada(false);
            setProgresso(false);
            setPlaying(false);
        }
    }, [conteudoId]);

    useEffect(() => { setTimeout(() => setCurrentTime(currentTime + 1), 2000); }, [currentTime]);
    
    // Apenas para vídeos
    useEffect(() => {
        if (!player) return;

        setLoading(false);
    }, [player]);

    // Para todos conteúdos
    useEffect(() => {
        if (!conteudo) return;
        if (!player?.current && !conteudo.is.pdf) return;

        fetch(env.nodeUrl + "conteudos/" + conteudo.moreInfo.id + "/tempo-consumido", {
            method: "POST",
            body: new URLSearchParams({
                'userId': user.user.id
            })
        })
            .then(r => r.json())
            .then(resposta => {
                const { times = false } = resposta;

                if (!times.length) return setProgresso(0);

                setCheckpoints(times);
                const [{ spent_time }] = times;

                setProgresso(spent_time);
                if (conteudo.is.youtube) player.current.seekTo(spent_time);
                else if (conteudo.is.mediadelivery) player.current.currentTime = spent_time;
                else return;
                setPlaying(true);
            });
    }, [loading]);

    // Vídeos --------------------------------------------------------------------

    useEffect(() => {
        if (!player?.current) return;
        if (conteudo.is.youtube) return;

        if (playing) {
            player.current.muted = false;
            setProgresso(player.current.currentTime + 1);
            player.current.play()
                .catch(e => setPlaying(false));
        } else {
            player.current.pause();
        }
    }, [playing]);

    // Media Delivery ----------------------------------------------------------
    useEffect(() => {
        if (!conteudo?.is?.mediadelivery) return;
        setTimeout(() => {
            if (!playing) return;
            if (!player?.current) return;

            const { currentTime } = player.current;
            setProgresso(currentTime);
        }, 1000);
    }, [progresso]);

    useEffect(() => {
        const porcentagem = getPorcentagem(progresso);
        setBarraDeProgresso(porcentagem);
        formataDuracao();
        salvaProgresso(progresso)
    }, [progresso]);


    // PDFs -------------------------------------------------------------------

    useEffect(() => {
        if (!conteudo?.is.pdf) return;

        setPopUpRightIcons([
            (pageNumber > 1) && { type: "previous-page" },
            (numPages != pageNumber) && { type: "next-page" }
        ]);

    }, [conteudo, numPages, pageNumber]);


    // Para todos conteúdos
    useEffect(() => {
        let lista = listaDeCheckPoints(conteudo);
        lista = lista.filter(item => /check/.test(item.props.className));

        if (lista.length >= 10) registraConclusao();
    }, [checkpoints]);

    return (
        <div className="container-conteudo" onMouseMove={() => setLastMovement(currentTime)}>
            <PopUpBackButton lastMovement={(currentTime <= lastMovement + 1) && { show: "block" }} />
            
            {conteudo?.is.pdf && <PdfPlayer
                conteudo={conteudo}
                setLoading={setLoading}
                progresso={progresso}
                salvaProgresso={salvaProgresso}
                popUps={(currentTime <= lastMovement + 1)}
                popUpOutlinePages={popUpOutlinePages}
                setPopUpOutlinePagesTrigger={setPopUpOutlinePagesTrigger}
                setPopUpOutlinePages={setPopUpOutlinePages}
                setNumPages={setNumPages}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                innerHeight={innerHeight}
                setInnerHeight={setInnerHeight}
            />}
            {conteudo?.is.mediadelivery && <MediadeliveryPlayer
                conteudo={conteudo}
                progresso={progresso}
                setPlayer={setPlayer}
            />}
            {conteudo?.is.youtube && <YoutubePlayer
                conteudo={conteudo}
                playing={playing}
                volume={volume}
                progresso={progresso}
                setProgresso={setProgresso}
                salvaProgresso={salvaProgresso}
                setBarraDeProgresso={setBarraDeProgresso}
                setPlayer={setPlayer}
                getPorcentagem={getPorcentagem}
            />}

            {loading && <Loader />}
            <div className="checkpoints" children={listaDeCheckPoints(conteudo)} />

            {conteudo?.is && <div
                className="modal-controles"
                {...(currentTime <= lastMovement + 1) && { show: "grid" }}
            >
                {!conteudo.is.pdf
                    ? <div
                        className="barra-de-progresso"
                        children={<>
                                {!!barraDeProgresso && <Slider
                                    value={barraDeProgresso}
                                    disableSwap
                                    onChange={mudaProgresso}
                                />}
                            {duracaoFormatada && <span children={duracaoFormatada} />}
                        </>}
                    />
                    : <div
                        className="controles"
                        children={<>
                            {<ZoomIn onClick={() => setInnerHeight(innerHeight + 100)} />}
                            {<ZoomOut onClick={() => setInnerHeight(innerHeight - 100)} />}
                        </>}
                    />}
                {!conteudo.is.pdf &&
                    (player?.current
                        ? <div
                            className="controles"
                            children={<>
                                {playing
                                    ? <Pause onClick={() => setPlaying(false)} />
                                    : <Play onClick={() => setPlaying(true)} />}
                                <div
                                    className="container-volume"
                                    onMouseEnter={() => setShowBarraDeVolume(true)}
                                    onMouseLeave={() => setShowBarraDeVolume(false)}
                                    children={<>
                                        {showBarraDeVolume && <div className="pop-up-volume"
                                            children={
                                                <Slider
                                                    value={volume}
                                                    disableSwap
                                                    orientation="vertical"
                                                    onChange={mudaVolume}
                                                />
                                            }
                                        />}
                                        <img
                                            className={showBarraDeVolume ? "hover" : ""}
                                            src={volumeIcon}
                                        />
                                    </>}
                                />
                            </>}
                        />
                    : <div />)
                }

                <h4 children={conteudo?.moreInfo.name} />

                <div className="pagination">
                    {popUpRightIcons
                        .filter(Boolean)
                        .map(icon =>
                            <PopUpTriggerIcon
                                key={icon.type}
                                conteudo={icon.content}
                                pageNumber={pageNumber}
                                identifier={icon.type}
                                setPageNumber={conteudo.is.pdf && setPageNumber}
                            />
                        )
                    }

                    <PopUpPages
                        popUpRightIcons={popUpRightIcons}
                        setPopUpRightIcons={setPopUpRightIcons}
                        popUpOutlinePagesTrigger={popUpOutlinePagesTrigger}
                        setPopUpOutlinePages={setPopUpOutlinePages}
                        conteudo={conteudo}
                    />
                </div>

            </div>}
        </div>
    )
};
