import { createContext, useContext, useMemo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Header } from "../components/Header";
import { useLocalStorage } from "./useLocalStorage";
import { trataVariaveisEnv } from "../funcoes-auxiliares";
import { ModalMaisInfo } from "../components/modalMaisInfo";

export const AuthContext = createContext();

export const AuthProvider = ({ children, userData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [paginaOndeFezABusca, setPaginaOndeFezABusca] = useState(location?.state?.paginaOndeFezABusca);

  const [user, setUser] = useLocalStorage('user', userData);

  const [textoDoInputDeBusca, setTextoDoInputDeBusca] = useState("");
  const [estaDigitando, setEstaDigitando] = useState(false);
  const [openModalTransicao, setOpenModalTransicao] = useState(false);
  const [modalMaisInfo, setModalMaisInfo] = useState(false);
  const [hideAppBar, setHideAppBar] = useState(/\/pdf/.test(location.pathname));
  const [env] = useState(trataVariaveisEnv);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', (e) => setInnerWidth(e.target.innerWidth));
  }, []);

  useEffect(() => {
    if (estaDigitando) setTimeout(() => setEstaDigitando(false), 1000);
  }, [estaDigitando]);

  useEffect(() => {
    setEstaDigitando(true);
  }, [textoDoInputDeBusca]);

  useEffect(() => {
    if (estaDigitando) return;
    
    if (textoDoInputDeBusca) {
      if (window.location.pathname != "/browse/busca" && !/\/conteudos\//.test(window.location.pathname)) setPaginaOndeFezABusca(window.location.pathname);
      navigate("/browse/busca", {
        state: {
          textoDoInputDeBusca,
          paginaOndeFezABusca: paginaOndeFezABusca || window.location.pathname
        } 
      });
    }
    else {
      if (textoDoInputDeBusca == '' && !location.state) return; 
      if (textoDoInputDeBusca == '' && typeof location.state?.paginaOndeFezABusca == 'undefined') return;
      if (!!location.state?.textoDoInputDeBusca && !!location.state?.paginaOndeFezABusca) return navigate(location.state.paginaOndeFezABusca);
    }
  }, [estaDigitando]);

  const handleMatricula = (item, identifier = false, blank = false) => {
    if (identifier === "assistir") {
      if (!blank) setOpenModalTransicao(true);
      setModalMaisInfo(false);
      setTextoDoInputDeBusca("");
    }
    let { id, canvas_secode, self_enrollment_code } = item;
    const codigoPraMatricula = canvas_secode || self_enrollment_code;
    id = item.canvas_id || id;
    const headers = { 'x-access-token': user.access_token };

    if (!item.enrollment && codigoPraMatricula) {
      fetch(env.nodeUrl+"conteudos/" + id + "/matricular-se?self_enrollment_code=" + codigoPraMatricula, { headers })
        .then(r => r.json())
        .then(r => {
          if (r) setTimeout(() => {
            if (identifier === "assistir") {
              if (blank) window.open("/browse/conteudos/" + id, '_blank');
              else navigate("/browse/conteudos/" + id, { state: { paginaOndeFezABusca } });
            }
          }, 1000);
        })
        .catch(e => setOpenModalTransicao(false))
    } else setTimeout(() => identifier === "assistir" && navigate("/browse/conteudos/" + id, { state: { paginaOndeFezABusca } }), 1000);
  }

  const redirecionaProLogin = () => window.location.href = "https://medflix.fgmed.org/login/oauth2/auth?client_id=1000000000000"+env.key+"&response_type=code&state=true&redirect_uri="+env.protocol+"//"+env.hostname+env.port+"/browse";

  const login = async (code) => {
    fetch(env.nodeUrl+"login",
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-code': code,
          'protocol': env.protocol,
          'hostname': env.hostname,
          'port': env.port,
          'key': env.key,
          'corshelper': env.corshelper
        },
      }
    )
      .then(r => r.ok ? r.json() : false)
      .then(dadosDoUsuario => {
        if (!dadosDoUsuario) return redirecionaProLogin();

        setUser(dadosDoUsuario);
        navigate("/browse");
      })
      .catch(err => {
        console.log(err);
        return redirecionaProLogin();
      });
  };

  const logout = () => {
    const headers = {
      'x-access-token': user.access_token,
      'corshelper': env.corshelper
    }
    fetch(env.nodeUrl+"logout", { headers })
      .then(r => r.ok)
      .then(r => {
        setUser(null);
        return redirecionaProLogin();
      })
  };

  const value = useMemo(
    () => ({
      env,
      innerWidth,
      user,
      setUser,
      openModalTransicao,
      login,
      logout,
      setOpenModalTransicao,
      setModalMaisInfo,
      hideAppBar,
      setHideAppBar,
      handleMatricula
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>
    {!hideAppBar && <Header innerWidth={innerWidth} textoDoInputDeBusca={textoDoInputDeBusca} setTextoDoInputDeBusca={setTextoDoInputDeBusca}/>}
    {children}
    {openModalTransicao && <div className="modal-transicao" />}
    {modalMaisInfo && <ModalMaisInfo modalMaisInfo={modalMaisInfo} setModalMaisInfo={setModalMaisInfo} />}
  </AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);
