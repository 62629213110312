import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer
} from "react-router-dom";
import { AuthLayout } from "./components/AuthLayout";
import { ProtectedLayout } from "./components/ProtectedLayout";
import { Conteudo } from "./pages/Conteudo";
import { Inicio } from "./pages/Inicio";
import { Espera } from "./pages/Espera";
import { ErrorPage } from "./pages/ErrorPage";
import { Conteudos } from "./pages/Conteudos";
import { Resultados } from "./pages/Resultados";

const getUserData = () =>
  new Promise((resolve) => {
      const user = window.localStorage.getItem("user");
      resolve(user);
  });
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route exact path="/" element={ <Espera />} />
      <Route
        element={<AuthLayout />}
        loader={() => defer({ userPromise: getUserData() })}
      >

        <Route exact path="/browse" element={<ProtectedLayout />}>
          <Route path="" element={<Inicio />} />

          <Route path="conteudos">
            <Route path="" element={<Conteudos />} />
            <Route path=":conteudoId" element={<Conteudo />} />
          </Route>
          <Route path="busca" element={<Resultados />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Route>
  )
);
