import { useEffect } from "react";
import { Loader } from "../components/Loader";
import { trataVariaveisEnv } from "../funcoes-auxiliares";
import { useNavigate } from "react-router";

export const Espera = () => {
    const { protocol, hostname, port, key } = trataVariaveisEnv();
    const navigate = useNavigate();
    
    useEffect(() => {
        let user = window.localStorage.getItem('user');

        if (user) navigate("/browse")
        else window.location.href = "https://medflix.fgmed.org/login/oauth2/auth?client_id=1000000000000"+key+"&response_type=code&state=true&redirect_uri="+protocol+"//"+hostname+port+"/browse";
    }, []);

    return <Loader />
}
