import { useEffect } from "react";
import { useOutlet } from "react-router";
import { useAuth } from "../hooks/useAuth";

export const ProtectedLayout = () => {
    const { user, login } = useAuth();
    const outlet = useOutlet();
    const code = new URL(window.location.href).searchParams.get('code');
    
    useEffect(() => {
        if (!user) login(code);
    }, [user]);

    return <>
        {outlet}
    </>
};

