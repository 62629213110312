import { useEffect, useState } from "react";
import { ReactComponent as ArrowBack } from "../images/svgs/arrow-back.svg";
import { ReactComponent as CheckMark } from "../images/svgs/check-mark.svg";
import { ReactComponent as Play } from "../images/svgs/play.svg";
import { ReactComponent as Pages } from "../images/svgs/pages.svg";
import { CircularProgress } from "@mui/material";
import { CustomSelect } from "../components/CustomSelect";
import { useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const PopUpPages = ({
        conteudo,
        setPopUpOutlinePages,
        popUpOutlinePagesTrigger,
        popUpRightIcons,
        setPopUpRightIcons
    }) => {
    const { handleMatricula, env } = useAuth();
    const [popUpPages, setPopUpPages] = useState(false);
    const [cursoAtual, setCursoAtual] = useState(false);
    const [conteudosRelacionados, setConteudosRelacionados] = useState([]);
    const [disciplinaAtual, setDisciplinaAtual] = useState(false);
    const [navegacaoPorDisciplinasECursos, setNavegacaoPorDisciplinasECursos] = useState(false);
    const [disciplinasEmComum, setDisciplinasEmComum] = useState([]);
    const [cursosRelacionados, setCursosRelacionados] = useState(false);
    const { conteudoId } = useParams();
    const [mostrarDetalhes, setMostrarDetalhes] = useState(conteudoId);
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     return () => setPopUpRightIcons(false)
    // }, []);

    useEffect(() => {
        if (!conteudo) return;
        if (!popUpRightIcons.length) setDisciplinaAtual(false);

        fetch(env.nodeUrl + "contents-from-lesson-lines?cv_content_id=" + conteudo.moreInfo.id)
            .then((resposta) => {
                if (resposta.ok) return resposta.json();
            })
            .then((resposta) => {
                if (!resposta?.metadados) return;

                let { contents, lessonLines, lessons } = resposta.metadados;
                const linesTipoVideoIds = [];
                lessonLines?.map(lessonLine => {
                    if (lessonLine.tipo == 'video-base') linesTipoVideoIds.push(lessonLine.cv_content_id[0]);
                    contents = contents.map(content => {
                        if (content?.id == lessonLine.cv_content_id[0]) content.module_id_from_his_correlative_lesson = lessonLine.lesson_id[0]
                        return content;
                    });
                });

                const [{ disciplina_ids }] = lessons;
                fetch(env.nodeUrl + "courses-from-modules?disciplina_ids=[" + disciplina_ids + "]")
                    .then(resposta => resposta.json())
                    .then(resposta => {
                        const { courses } = resposta.metadados;
                        setCursoAtual(courses[0]);
                        setCursosRelacionados(courses);

                    });

                const currentLessonLine = lessonLines.filter(lessonLine => lessonLine.cv_content_id[0] == conteudo.moreInfo.id);
                const [{ lesson_related_content }] = currentLessonLine;
                const idMaterialComplementar = lesson_related_content ? lesson_related_content[0] : false;
                const lessonLineOfMaterialComplementar = lessonLines.filter(lessonLine => lessonLine.id == idMaterialComplementar);
                const [materialComplementar] = contents.filter(content => content.id == lessonLineOfMaterialComplementar[0]?.cv_content_id[0]);

                if (popUpRightIcons.filter(icon => icon?.type == "material-complementar").length) return;
                setPopUpRightIcons([
                    ...popUpRightIcons,
                    materialComplementar && { type: "material-complementar", content: materialComplementar }
                ]);
            });

        if (!conteudosRelacionados?.length) return;
        const [conteudoAtual] = conteudosRelacionados.filter(content => content?.canvas_id == conteudoId);
        if (conteudoAtual) setMostrarDetalhes(conteudoAtual.canvas_id);
    }, [conteudo]);

    useEffect(() => {
        if (!cursoAtual) return;

        fetch(env.nodeUrl + "modules?disciplina_ids=[" + cursoAtual.disciplina_ids + "]")
            .then(resposta => resposta.json())
            .then(resposta => {
                let { modules } = resposta.metadados;
                modules = modules.filter(module => module.cv_content_ids?.length);
                const [currentModule] = modules.filter(module => conteudo.moreInfo.modules_ids.includes(module.id));
                setDisciplinasEmComum(modules);
                if (!disciplinaAtual) setDisciplinaAtual(currentModule);
            });

    }, [cursoAtual]);

    useEffect(() => {
        if (!disciplinaAtual) return;

        fetch(env.nodeUrl + "contents-from-lessons?disciplina_ids=[" + disciplinaAtual.id + "]")
            .then(resposta => resposta.json())
            .then(resposta => {
                let { contents } = resposta.metadados;
                contents = contents.filter(content => content.content_type[0] == 2);
                setConteudosRelacionados(contents);
                setLoading(false);
                setNavegacaoPorDisciplinasECursos(false);
            })
            .catch(e => setNavegacaoPorDisciplinasECursos(false));

    }, [disciplinaAtual]);

    useEffect(() => {
        if (!conteudosRelacionados?.length) return;
        if (conteudo?.is.pdf) return;
        if (popUpRightIcons?.filter(icon => ["next-video", "previous-video"].includes(icon?.type)).length) return;

        const conteudoAtual = conteudosRelacionados.filter(c => c.canvas_id == conteudo?.canvas_id)[0];
        const indiceDoConteudoAtual = conteudosRelacionados?.indexOf(conteudoAtual);
        const proximoVideo = conteudosRelacionados[indiceDoConteudoAtual + 1];
        const videoAnterior = conteudosRelacionados[indiceDoConteudoAtual - 1];

        setPopUpRightIcons([
            ...popUpRightIcons,
            videoAnterior && { type: "previous-video", content: videoAnterior },
            proximoVideo && { type: "next-video", content: proximoVideo }
        ]);
    }, [conteudosRelacionados]);

    return ((!!conteudosRelacionados?.length || popUpOutlinePagesTrigger) &&
        <div
            className="pop-up-icon-trigger"
            onMouseOver={() => {
                if (popUpOutlinePagesTrigger) setPopUpOutlinePages({ show: "block" })
                else setPopUpPages({ show: "block" })
            }}
            onMouseLeave={() => {
                setPopUpPages(false);
                setNavegacaoPorDisciplinasECursos(false);
            }}
        >
            <div className="pop-up-of-the-icon lista" {...popUpPages}>

                <div className={["navegacao-por-disciplinas-e-cursos", navegacaoPorDisciplinasECursos?.show].filter(Boolean).join(" ")}>
                    <div className="container-custom-select">
                        {cursoAtual && <CustomSelect
                            identifier="cursos"
                            lista={cursosRelacionados}
                            currentSelected={cursoAtual}
                            setLista={setDisciplinasEmComum}
                        />}
                        {loading && <CircularProgress thickness={1.7} />}
                    </div>

                    <ul className="lista-de-disciplinas" {...disciplinasEmComum && { show: "block" }}>
                        {disciplinasEmComum.map((content, index) => {
                            return <li
                                className="item-da-lista"
                                key={index}
                                onClick={() => {
                                    if (disciplinaAtual.id == content.id) return setNavegacaoPorDisciplinasECursos(false);
                                    setLoading(true);
                                    setDisciplinaAtual(content);
                                }}
                            >
                                <div className="check-and-name">
                                    <CheckMark className={disciplinaAtual.id == content.id ? "block" : ""} />
                                    <span className="video-name" children={content.name} />
                                </div>
                                <ArrowBack className="arrow-back" />
                            </li>
                        })}
                    </ul>
                </div>

                {disciplinasEmComum &&
                    <div className="container-disciplina-atual" onClick={() => setNavegacaoPorDisciplinasECursos({ show: "block" })}>
                        <ArrowBack />
                        <span className="disciplina-atual" children={disciplinaAtual.name} />
                    </div>
                }

                <ul className="lista-de-videos" {...conteudosRelacionados && { show: "block" }}>
                    {conteudosRelacionados?.map((content, index) => (
                        <li key={index} className={mostrarDetalhes == content.canvas_id ? "mostrar-detalhes" : ""}>
                            <div className="container-video-name" onClick={() => setMostrarDetalhes(content.canvas_id)}>
                                <span className="video-index">{index + 1}</span>
                                <span className="video-name">{content.name}</span>
                            </div>
                            {<div className="video-details">
                                <div className="container-imagem-video">
                                    {mostrarDetalhes != conteudoId && <a onClick={() => handleMatricula(content, "assistir")}><Play /></a>}
                                    <img src={content.thumbnail} />
                                </div>
                                <div>{content.description}</div>
                            </div>}
                        </li>
                    ))}
                </ul>

            </div>
            <div
                onMouseOver={() => {
                    if (popUpOutlinePagesTrigger) setPopUpOutlinePages({ show: "block" })
                    else setPopUpPages({ show: "block" })
                }}
                onMouseLeave={() => {
                    setPopUpOutlinePages(false);
                    setPopUpPages(false);
                    setNavegacaoPorDisciplinasECursos(false);
                }}
                children={<Pages />}
            />
        </div>
    )
}