import { Alert, LinearProgress } from "@mui/material";
import { Suspense } from "react";
import { Await, useLoaderData, useOutlet } from "react-router"
import { AuthProvider } from "../hooks/useAuth";

export const AuthLayout = () => {
    const outlet = useOutlet();
    const { userPromise } = useLoaderData();
    
    return (
        <Suspense fallback={<LinearProgress />}>
            <Await
                resolve={userPromise}
                errorElement={<Alert severity="error">Algo deu errado</Alert>}
                children={
                    (user) => {
                        return <>
                            <AuthProvider userData={user}>
                                {outlet}
                            </AuthProvider>
                        </>
                    }
                }
            />
        </Suspense>
    );
};
