import { useEffect, useState } from "react"
import { ReactComponent as ArrowDown } from "../images/svgs/arrow-down.svg";
import { useAuth } from "../hooks/useAuth";

export const CustomSelect = ({ identifier, lista, setLista, setConteudosRelacionados, currentSelected, alignToRight }) => {
    const { env } = useAuth();
    const [popUpSelect, setPopUpSelect] = useState(false);
    const [selected, setSelected] = useState(currentSelected);
    const [position, setPosition] = useState(false);

    useEffect(() => {
        if (alignToRight) setPosition({ right: 0 });
        else setPosition({ left: 0 });
    }, []);

    useEffect(() => {
        if (!selected) return;

        if (identifier == "cursos") {
            const { disciplina_ids } = selected;
            fetch(env.nodeUrl + "modules?disciplina_ids=[" + disciplina_ids + "]")
                .then(resposta => resposta.json())
                .then(resposta => {
                    let { modules } = resposta.metadados;
                    modules = modules.filter(module => module.cv_content_ids?.length);
                    setLista(modules);
                })
        } else if (identifier == "disciplinas") {
            fetch(env.nodeUrl + "contents-from-lessons?disciplina_ids=[" + selected.id + "]")
                .then(resposta => resposta.json())
                .then(resposta => {
                    let { contents, lessonLines } = resposta.metadados;
                    const linesTipoVideoIds = lessonLines.filter(lessonLine => lessonLine.tipo == 'video-base').map(line => line.cv_content_id[0]);
                    const conteudosFiltrados = contents.filter(content => linesTipoVideoIds.includes(content.id));
                    setConteudosRelacionados(conteudosFiltrados);
                });
        }
    }, [selected]);

    return !!lista.length && <div className="custom-select" onMouseLeave={() => setPopUpSelect(false)}>
    <div className="option-selected" onClick={() => setPopUpSelect(true)}>
        {selected && <span children={selected.name} />}
        <ArrowDown />
    </div>
    {popUpSelect && <div className="select" style={position} >
        {lista.map(item => {
            return <div
                className="option"
                key={item.id}
                onClick={() => {
                    setSelected(item);
                    setPopUpSelect(false);
                }}
            >
                {item.name}
                {item?.cv_content_ids && <span children={`(${item?.cv_content_ids?.length} Aulas)`} />}
                {item?.disciplina_ids && <span children={`(${item.disciplina_ids.length} Disciplinas)`} />}
            </div>
        })}
    </div>}
</div>}
