import { useEffect, useRef } from "react";
import ReactPlayer from "react-player";

export const YoutubePlayer = ({ conteudo, playing, setPlayer, volume, progresso, setProgresso, setBarraDeProgresso, getPorcentagem, salvaProgresso }) => {
    const player = useRef(null);

    useEffect(()=> {
        setPlayer(player);
    }, []);

    return <div style={{ overflow: "hidden" }} >
        <div className="modal-de-bloqueio-do-youtube" />
        <ReactPlayer
            ref={player}
            url={conteudo.url_of_the_video}
            playing={playing}
            width="100%"
            height="100vh"
            light={!playing}
            playIcon={<img style={{ width: "80vw" }} src={conteudo.moreInfo.thumbnail} />}
            volume={volume / 100}
            config={{
                youtube: {
                    playerVars: {
                        start: progresso
                    }
                }
            }}
            onEnded={() => setPlaying(false)}
            onProgress={({ playedSeconds }) => {
                const segundos = parseInt(playedSeconds);
                if (!segundos) return;
                
                setProgresso(segundos);
            }}
        />
    </div>
}
