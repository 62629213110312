import { Box, CircularProgress } from "@mui/material";

export const Loader = () => {
    const sx = {
        position: "absolute",
        backgroundColor: 'custom.preto_quase_claro',
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    };

    return <Box sx={sx}>
        <CircularProgress thickness={1.7} />
    </Box>
}
