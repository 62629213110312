import { BotaoArredondado } from "./BotaoArredondado";
import { BotoesDeAvaliacao } from "./BotoesDeAvaliacao";
import { ReactComponent as Play } from "../images/svgs/play.svg";
import { ReactComponent as Plus } from "../images/svgs/plus.svg";
import { ReactComponent as Check } from "../images/svgs/check-mark.svg";
import { MoreDetails } from "./MoreDetails";
import { Close } from "@mui/icons-material";
import { BotaoRedondo } from "./BotaoRedondo";
import { useState, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import { CustomSelect } from "./CustomSelect";
import { CircularProgress } from "@mui/material";
import { CardAndTextContent } from "./CardAndTextContent";

const geraStringComADuracao = (conteudo) => {
    const { total_time, content_type } = conteudo;

    let string = "";
    if (content_type.includes('Vídeo')) {
        const horas = Math.floor(total_time / 3600);
        const minutos = Math.floor((total_time - (horas * 3600)) / 60);
        const segundosRestantes = total_time - (horas * 3600) - (minutos * 60);

        if (horas > 0) string += horas + "h ";
        if (minutos > 0) string += minutos + "min ";
        if (horas == 0) string += segundosRestantes + "seg";
    } else {
        string = total_time ? (total_time + ' páginas') : "";
    }
    return string;
}

export const ModalMaisInfo = ({ modalMaisInfo, setModalMaisInfo }) => {
    const { handleMatricula, env, user } = useAuth();
    const [conteudo, setConteudo] = useState(modalMaisInfo);
    const [conteudosRelacionados, setConteudosRelacionados] = useState([]);
    const [materiaisComplementares, setMateriaisComplementares] = useState([]);
    const [autores, setAutores] = useState(false);
    const [aulas, setAulas] = useState(false);
    const [disciplinaAtual, setDisciplinaAtual] = useState(false);
    const [disciplinasDoConteudo, setDisciplinasDoConteudo] = useState([]);
    const [disciplinasEmComum, setDisciplinasEmComum] = useState([]);
    const [cursoAtual, setCursoAtual] = useState(false);
    const [cursosRelacionados, setCursosRelacionados] = useState(false);

    const [iconeDeAvaliacao, setIconeDeAvaliacao] = useState(conteudo?.interaction?.type);
    const [iconeDaMihaLista, setIconeDaMinhaLista] = useState(conteudo?.enrollment && "check");
    const [loading, setLoading] = useState(false);
    const [checkedEnrollment, setCheckedEnrollment] = useState(false);
    
    useEffect(() => {
        if (checkedEnrollment) return;
        if (conteudo?.enrollment) return setCheckedEnrollment(true);
        setLoading(true);

        const headers = { 'x-access-token': user.access_token };
        fetch(env.nodeUrl + "enrollments?canvas_id=" + conteudo.canvas_id + "&user_id="+user.user.id, { headers })
            .then(resposta => resposta.json())
            .then(resposta => {
                const { enrollment } = resposta.metadados;
                setConteudo({
                    ...conteudo,
                    enrollment: enrollment || false
                });
                setIconeDaMinhaLista(enrollment || false);
                setLoading(false);
                setCheckedEnrollment(true);
            })
            .catch(e => setLoading(false));
    }, []);

    useEffect(() => {
        fetch(env.nodeUrl + "authors?cv_content_id=" + conteudo.id)
            .then((resposta) => {
                if (resposta.ok) return resposta.json();
            })
            .then((resposta) => {
                if (resposta.message) return; 
                const { authors } = resposta.metadados;
                setAutores(authors);
            });

        fetch(env.nodeUrl + "lessons-from-lesson-lines?cv_content_id=" + conteudo.id)
            .then((resposta) => {
                if (resposta.ok) return resposta.json();
            })
            .then((resposta) => {
                if (resposta.message) return; 
                const { lessons } = resposta.metadados;
                setAulas(lessons);
            });
    }, []);


    useEffect(() => {
        if (!aulas?.length) return;

        const disciplina_ids = aulas.map(aula => aula.disciplina_ids.join());

        fetch(env.nodeUrl + "modules?disciplina_ids=[" + disciplina_ids + "]")
            .then((resposta) => {
                if (resposta.ok) return resposta.json();
            })
            .then((resposta) => {
                const { modules } = resposta.metadados;
                setDisciplinasDoConteudo(modules);
                setDisciplinasEmComum(modules);
                setDisciplinaAtual(modules[0])
            });

        fetch(env.nodeUrl + "contents-from-lessons?ids=[" + aulas.map(aula => aula.id) + "]")
            .then(resposta => resposta.json())
            .then(resposta => {
                let { contents, lessonLines } = resposta.metadados;
                const linesTipoVideoIds = lessonLines.filter(lessonLine => lessonLine.tipo == 'material-complementar').map(line => line.cv_content_id[0]);
                const conteudosFiltrados = contents.filter(content => linesTipoVideoIds.includes(content.id));
                
                setMateriaisComplementares(conteudosFiltrados);
            });

    }, [aulas]);

    useEffect(() => {
        if (!disciplinasDoConteudo) return;

        fetch(env.nodeUrl + "courses-from-modules?disciplina_ids=[" + disciplinasDoConteudo.map(disciplina => disciplina.id) + "]")
            .then((resposta) => {
                if (resposta.ok) return resposta.json();
            })
            .then((resposta) => {
                const { courses } = resposta.metadados;
                setCursosRelacionados(courses);
                setCursoAtual(courses[0]);
            });

    }, [disciplinasDoConteudo]);

    return <div className="container-modal-mais-informacoes">
        <div className="modal-mais-informacoes">
            <div className="container-modal-header" style={{ backgroundImage: `url(${conteudo.thumbnail || conteudo.image_download_url})` }}>
                <div className="modal-header">
                    <div
                        className="close-modal"
                        onClick={() => setModalMaisInfo(false)}
                        children={<Close />}
                    />
                    <div className="titulo-e-botoes">
                        <img src={conteudo.custom_title} />

                        <div className="botoes">
                            <BotaoArredondado
                                tipo="branco"
                                identifier="assistir"
                                icone={window.innerWidth > 767 && <Play />}
                                texto="Assistir"
                                item={conteudo}
                            />
                            {window.innerWidth > 767 && <BotaoRedondo
                                identifier="matricula"
                                texto={iconeDaMihaLista ? "Em Minha lista" : "Adicionar à Minha lista"}
                                icone={(loading && <CircularProgress thickness={1.7} />) ||(iconeDaMihaLista ? <Check /> : <Plus />)}
                                item={conteudo}
                                setIconeDaMinhaLista={setIconeDaMinhaLista}
                            />}
                            {window.innerWidth > 767 && <BotoesDeAvaliacao
                                item={conteudo}
                                iconeDeAvaliacao={iconeDeAvaliacao}
                                setIconeDeAvaliacao={setIconeDeAvaliacao}
                            />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-modal-footer">
                <div className="details">
                    <span
                        className="duration"
                        children={geraStringComADuracao(conteudo)}
                    />
                    <p children={conteudo.description} />
                </div>
                <div className="more-details">
                    <MoreDetails texto="Tipo" array={conteudo.content_type} />
                    <MoreDetails texto="Autores" array={autores} />
                    <MoreDetails texto="Disciplinas" array={disciplinasDoConteudo} />
                    <MoreDetails texto="Cursos" array={cursosRelacionados} />
                </div>
            </div>
            {<div className="container-episodios">
                {!!cursosRelacionados?.length && <h1 children="Aulas" />}
                <div className="container-custom-select">
                    {disciplinaAtual && <CustomSelect
                        identifier="disciplinas"
                        lista={disciplinasEmComum}
                        setConteudosRelacionados={setConteudosRelacionados}
                        currentSelected={disciplinaAtual}
                    />}
                    {cursoAtual && <CustomSelect
                        identifier="cursos"
                        lista={cursosRelacionados}
                        setLista={setDisciplinasEmComum}
                        currentSelected={cursoAtual}
                        alignToRight
                    />}
                </div>


                {!!conteudosRelacionados.length && <div className="episodios">
                    {conteudosRelacionados.map((content, index) => {
                        return <div
                            key={index}
                            className={`episodio ${content.id == conteudo.id && "atual"}`}
                            onClick={() => {
                                setModalMaisInfo(false);
                                handleMatricula(content, 'assistir');
                            }}
                        >
                            <span children={index + 1} />
                            <img src={content.thumbnail} />
                            <div className="textos-sobre-o-episodio">
                                <b children={content.name} />
                                <p children={content.description} />
                            </div>
                        </div>
                    })}
                </div>}
            </div>}
            {!!materiaisComplementares.length && <div className="container-sobre-materiais-complementares">
                <h2>Materiais Complementares</h2>
                <div className="grid-dos-materiais-complementares">
                    {materiaisComplementares.map(material => (
                        <CardAndTextContent
                            key={material.id}
                            item={material}
                            duracao={geraStringComADuracao(material)}
                        />
                    ))}
                </div>
            </div>}
            <div id="container-sobre-conteudo" className="container-sobre-conteudo">
                <h2>Sobre {conteudo.name}</h2>
                <MoreDetails texto="Tipo" array={conteudo.content_type} />
                <MoreDetails texto="Autores" array={autores} />
                <MoreDetails texto="Disciplinas" array={disciplinasDoConteudo} showAll />
                <MoreDetails texto="Cursos" array={cursosRelacionados} showAll />
            </div>
        </div>
    </div>
}


