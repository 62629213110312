import { CardContent } from "../components/CardContent"
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../components/Loader";
import { useState, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import { CircularProgress } from "@mui/material";
import { Footer } from "../components/Footer";

export const Resultados = () => {
    const { state } = useLocation();
    const { env, user, hideAppBar, setHideAppBar } = useAuth();
    const navigate = useNavigate();

    const [resultados, setResultados] = useState([]);
    const [todosResultadosExibidos, setTodosResultadosExibidos] = useState(false);
    const [mensagem, setMensagem] = useState("");
    const [requestedPage, setRequestedPage] = useState(0);
    const [loading, setLoading] = useState(false);

    const buscaConteudo = async () => {
        if (todosResultadosExibidos == state.textoDoInputDeBusca) return
        setLoading(true);
        const comparaValores = await new Promise((resolve) => {
            setTimeout(() => {
                const valorAtualDoInput = document.querySelector(".search-field")?.value || document.querySelector(".mobile-search-field")?.value;
                const valorPermaneceOMesmo = state.textoDoInputDeBusca == valorAtualDoInput
                resolve(!valorPermaneceOMesmo);
            }, 500);
        });

        if (comparaValores) return;

        const headers = { 'x-access-token': user.access_token };
        fetch(env.nodeUrl + "conteudos/busca?palavra=" + state.textoDoInputDeBusca + "&user_id=" + user.user.id + "&page=" + requestedPage + "&query=todos", { headers })
            .then(resposta => resposta.json())
            .then(resposta => {
                const { contents, tags } = resposta.metadados;
                setLoading(false);

                if ((contents.empty && tags.empty) && requestedPage) return setTodosResultadosExibidos(state.textoDoInputDeBusca);

                if (contents.message && tags.message) setMensagem(contents.message);
                else setMensagem("");
                

                if (requestedPage == 0) {
                    setResultados(contents);
                } else {
                    setResultados([
                        ...resultados,
                        ...contents,
                    ]);
                }
            })
            .catch(e => {
                setMensagem(`Não foi possível buscar por "${valorAtualDoInput}"`)
            });
    }

    useEffect(() => {
        setMensagem("");
        setResultados([]);

        setRequestedPage(0);
        buscaConteudo();
    }, [state]);

    useEffect(() => {
        buscaConteudo();
    }, [requestedPage]);

    useEffect(() => {
        const valorAtualDoInput = document.querySelector(".search-field")?.value || document.querySelector(".mobile-search-field")?.value;
        if (!hideAppBar) setHideAppBar(false);
        if (!user) return;
        if (!state.textoDoInputDeBusca || !valorAtualDoInput) navigate("/browse");
    }, []);

    const handleScroll = (e) => {
        const { scrollHeight, clientHeight, scrollTop } = e.target;

        if (loading) return;
        if (!scrollTop) return;
        if (scrollTop == scrollHeight - clientHeight) setRequestedPage(requestedPage + 1);
    }

    return <div
        onScroll={handleScroll}
        className="container-resultados"
        children={<>
            {mensagem
                ? <div
                    className="mensagem"
                    children={<div>
                        <p children={mensagem} />
                        Sugestões:
                        <ul>
                            <li children="Tente palavras-chave diferentes" />
                            <li children="Procurando um vídeo ou PDF?" />
                            <li children="Experimente buscar por título, descrição ou autor" />
                            <li children="Experimente buscar uma categoria, como Pediatria, Oftalmologia ou Ultrassonografia" />
                        </ul>
                    </div>}
                />
                : <>
                    <h1 children={`Resultados com '${state.textoDoInputDeBusca}'`} />
                    <div
                        className="resultados"
                        children={resultados?.length
                            ? <>
                            {resultados.map((resultado) => <div
                                className="grid-resultados"
                                key={resultado.id}
                                children={<CardContent innerWidth={innerWidth} item={resultado} />}
                            />)}
                            {(resultados.length && loading) ? <div className="container-loading" children={<CircularProgress thickness={1.7} />} /> : <></>}
                            </>
                            : <Loader />}
                    />
                </>
            }
            <Footer />
        </>}
    />
}