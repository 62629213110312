import { useAuth } from "../hooks/useAuth";
import BlueFace from "../images/blue-face.png";
import Logo from "../images/logo-medflix.png";
import { ReactComponent as Arrow } from "../images/svgs/arrow.svg";
import { ReactComponent as Lupa } from "../images/svgs/lupa.svg";
import { useEffect, useRef, useState } from "react";
import { Close, MenuOutlined } from "@mui/icons-material";
import { RouteLink } from "./RouteLink";

export const Header = ({ innerWidth, textoDoInputDeBusca, setTextoDoInputDeBusca }) => {
    const { user, logout, setModalMaisInfo } = useAuth();
    const ref = useRef(null);
    const refMenu = useRef(null);

    const [linkAtivo, setLinkAtivo] = useState("");
    const [openMenu, setOpenMenu] = useState("");
    const [input, setInput] = useState(false);
    const [menuMobile, setMenuMobile] = useState(false);
    const [isMobile, setIsMobile] = useState(innerWidth < 767);

    const buscaConteudo = (e) => {
        const { value } = e.target;
        setTextoDoInputDeBusca(value);
    }

    const handleLogout = () => {
        setOpenMenu(false);
        logout();
    }

    useEffect(() => {
        document.addEventListener("click", (e) => {
            if (window.innerWidth < 767) return setMenuMobile(e.target.closest(".menu-mobile-trigger") || e.target.closest(".menu-mobile") == refMenu.current);

            if (document.querySelector(".search-field")?.value || document.querySelector(".mobile-search-field")?.value) return setInput(true);
            setInput(e.target.closest(".search-container") == ref.current);
        });
    }, []);

    useEffect(() => {
        setIsMobile(innerWidth < 767);
    }, [innerWidth]);

    const showElement = (condition, displayType = "block") => condition ? { show: displayType } : {};

    return <header onClick={() => setModalMaisInfo(null)}>
        <div
            {...showElement(isMobile, "flex")}
            ref={refMenu}
            className={["menu-mobile", menuMobile && user ? "menu-aberto" : ""].join(" ")}
            children={<>
                <div className="dados-usuario">
                    <img src={BlueFace} />
                    <b children={user?.user.name} />
                </div>
                <b children="Sair da Medflix" onClick={handleLogout} />
                <div className="links-mobile">
                    <RouteLink
                        url="/browse"
                        text="Início"
                        linkAtivo={linkAtivo}
                        setLinkAtivo={setLinkAtivo}
                        />
                    <RouteLink
                        url="/browse/conteudos"
                        text="Conteúdos"
                        linkAtivo={linkAtivo}
                        setLinkAtivo={setLinkAtivo}
                    />
                </div>
            </>}
        />
        <MenuOutlined {...showElement(isMobile)} className="menu-mobile-trigger" onClick={() => setMenuMobile(true)} />
        <img className="logo" src={Logo} />
        <div className="menus-do-header">
            <div className="main-menu">
                <RouteLink
                    url="/browse"
                    text="Início"
                    linkAtivo={linkAtivo}
                    setLinkAtivo={setLinkAtivo}
                />
                <RouteLink
                    url="/browse/conteudos"
                    text="Conteúdos"
                    linkAtivo={linkAtivo}
                    setLinkAtivo={setLinkAtivo}
                />
            </div>
            <div className="items-a-direita">
                <div ref={ref} className="search-container">
                    <input
                        {...showElement(input && !isMobile)}
                        type="text"
                        id="input-search"
                        onChange={buscaConteudo}
                        value={textoDoInputDeBusca}
                        className="search-field"
                        placeholder="Título, descrição e autor"
                    />
                    <input
                        {...showElement(isMobile)}
                        type="text"
                        onChange={buscaConteudo}
                        value={textoDoInputDeBusca}
                        className="mobile-search-field"
                        placeholder="Buscar"
                    />
                    <label htmlFor="input-search" className={input ? "input-aberto" : ""} children={<Lupa className="lupa" />} />
                    <Close
                        onClick={() => setTextoDoInputDeBusca("")}
                        className={["close", input && textoDoInputDeBusca ? "input-aberto" : ""].join(" ")}
                    />
                </div>
                <div
                    {...showElement(!isMobile, "flex")}
                    className={["side-menu-trigger", openMenu.show].join(" ")}
                    onMouseOver={() => setOpenMenu({ show: "block" })}
                    onMouseLeave={() => setOpenMenu(false)}
                    children={<>
                        <div className="trigger">
                            <img src={BlueFace} className="blue-face" />
                            <Arrow className="arrow" />
                        </div>
                        <div
                            className="side-menu"
                            onMouseOver={() => setOpenMenu({ show: "block" })}
                            {...openMenu}
                        >
                            <div className="side-menu-item avatar">
                                <img src={BlueFace} className="blue-face" /> <span>{user?.user.name}</span>
                            </div>

                            <hr />

                            <div className="side-menu-item" onClick={handleLogout}>
                                <span>Sair da Medflix</span>
                            </div>
                        </div>
                    </>}
                />
            </div>
        </div>
    </header>
};
