import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { ReactComponent as SliderArrow } from "../images/svgs/slider-arrow.svg";
import { useAuth } from "../hooks/useAuth";
import { CardContent } from "./CardContent";

export const Slider = ({titulo, endpoint, query="", innerWidth}) => {
    const { user, env, logout } = useAuth();

    const [leftPosition, setLeftPosition] = useState(0);
    const [nextPageButton, setNextPageButton] = useState(true);
    const [loading, setLoading] = useState(false);
    const [requestedPage, setRequestedPage] = useState(/minha-lista/.test(endpoint) ? 1 : 0);
    const [lista, setLista] = useState(false);
    const [touchStart, setTouchStart] = useState(false);
    const [touchEnd, setTouchEnd] = useState(false);
  
    const tamanhoDaThumbnail = innerWidth < 767 ? 174 : 240;
    const numeroDeColunas = Math.floor(innerWidth / tamanhoDaThumbnail);
    const marginRight = 6;
    const vaoEntreColunas = marginRight * numeroDeColunas;
    const minSwipeDistance = 25;
  
    const onTouchStart = (e) => {
      const [{ clientX }] = e.targetTouches;
      
      setTouchEnd(false);
      setTouchStart(clientX);
    }
    
    const onTouchMove = (e) => {
      const [{ clientX }] = e.targetTouches;
  
      setTouchEnd(clientX);
    }
  
    const onTouchEnd = (e) => {
      if (!touchStart || !touchEnd) return;
  
      const distance = touchStart - touchEnd;
      const isLeftSwipe = distance > minSwipeDistance;
      const isRightSwipe = distance < -minSwipeDistance;
  
      if (isLeftSwipe) goToNextPage();
      if (isRightSwipe) goToPreviousPage();
  
    }

    useEffect(()=> {
        if (!user) return;

        const headers = { 'x-access-token': user.access_token };
        let busca = query
            ? "&query=" + query
            : "&palavra=" + titulo;

        fetch(env.nodeUrl+endpoint+"?page="+requestedPage+"&user_id="+user.user.id+busca, { headers })
            .then(resposta => resposta.ok
                ? resposta.json()
                : logout())
            .then(resposta => {
                let { contents } = resposta.metadados;
                setLista(contents);
            })
            .catch((e) => {
                console.log(e);
                logout();
            });
    }, [user])

    const goToPreviousPage = () => {
        if (!nextPageButton) setNextPageButton(true); 
        const newLeftPosition = leftPosition <= 0 ? 0 : leftPosition - 100;
        setLeftPosition(newLeftPosition);
    };

    const goToNextPage = () => {
        const headers = { 'x-access-token': user.access_token };
        
        let numeroDeSwipes = Math.ceil(lista.length / numeroDeColunas);
        let nextLeftPosition = leftPosition + 100;
        let swipesDado = nextLeftPosition / 100;
        let newLeftPosition = swipesDado >= numeroDeSwipes ? leftPosition : nextLeftPosition;

        if (swipesDado >= numeroDeSwipes) {
            setLoading(true );
            const newRequestedPage = requestedPage + 1;
            let busca = query
                ? "&query=" + query
                : "&palavra=" + titulo;

            fetch(env.nodeUrl + endpoint + "?page=" + newRequestedPage + busca, { headers })
                .then(resposta => resposta.json())
                .then(resposta => {
                    const { contents } = resposta.metadados;
                    setLoading(false);
                    if (!contents?.length) return setNextPageButton(false);
                    
                    const listaAtualizada = [
                        ...lista,
                        ...contents
                    ];
                    setLista(listaAtualizada);

                    setRequestedPage(newRequestedPage);
                    numeroDeSwipes = Math.floor(listaAtualizada.length / numeroDeColunas);
                    nextLeftPosition = leftPosition + 100;
                    swipesDado = nextLeftPosition / 100;
                    newLeftPosition = swipesDado >= numeroDeSwipes ? leftPosition : nextLeftPosition;
                    setLeftPosition(newLeftPosition);
                })
                .catch(e => {
                    setLoading(false);
                    setNextPageButton(false);
                })
        } else {
            setLoading(false);
        }
        setLeftPosition(newLeftPosition);
    }

    return <>
        {!!lista?.length && <div className="container-slider" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            <h1>{titulo}</h1>
            <div className="slider">
                <div className="pages" style={{ left: `calc( (${tamanhoDaThumbnail * numeroDeColunas}px + ${vaoEntreColunas}px) * ${leftPosition / 100} * -1 )` }}>
                    {lista?.map((item, index) => {
                        if (!item.id) item = item.course;
                    
                        return <CardContent
                            indiceDacoluna={index % parseInt(innerWidth / tamanhoDaThumbnail) }
                            tamanhoDoSlider={parseInt(innerWidth / tamanhoDaThumbnail) - 1}
                            item={item}
                            key={item.id}
                            titulo={titulo}
                        />
                    })}
                    {loading && <div
                        className="loader"
                        children={<CircularProgress thickness={1.7}/>}
                    />}
                </div>
                {(leftPosition > 0 && innerWidth > 767) && <div
                    className="slider-arrow-container left"
                    onClick={goToPreviousPage}
                    children={<SliderArrow />}
                />}
                {(nextPageButton && innerWidth > 767) && <div
                    className="slider-arrow-container right"
                    onClick={goToNextPage}
                    children={<SliderArrow />}
                />}
            </div>
        </div>}
    </>
}
