import { Box } from "@mui/system";
import { useState } from "react";
import { useAuth } from "../hooks/useAuth";

export const BotaoRedondo = ({ icone, setIconeDeAvaliacao, setIconeDaMinhaLista, identifier, tipo, texto = false, item = false }) => {
    const [hoverMessage, setHoverMessage] = useState(false);
    const { handleMatricula, setModalMaisInfo, user, env } = useAuth();

    const handleClick = () => {
        if (identifier == "matricula") setIconeDaMinhaLista("check");
        let id = item.canvas_id ? item.canvas_id : item.id;
        const config = {
            method: "POST",
            body: new URLSearchParams({
                'userId': user.user.id,
                'tipo': tipo
            })
        };
        if (identifier == "matricula" || identifier == "assistir" ) handleMatricula(item, identifier);
        if (identifier == "mais-info") setModalMaisInfo(item);
        if (identifier == "avaliacao")  {
            setIconeDeAvaliacao(tipo);
            fetch(env.nodeUrl+"conteudos/"+id+"/registrar-interacao/", config);
        }
    }

    return <div
        className="container-botao-redondo"
        children={<>
            {texto && <Box
                className={`hover-message ${hoverMessage && "show-message"}`}
                children={texto}
            />}
            <Box
                onClick={handleClick}
                className={"botao-redondo-de-"+identifier}
                onMouseOver={() => setHoverMessage(true)}
                onMouseLeave={() => setHoverMessage(false)}
                children={icone}
            />
        </>}
    />
}
