import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth"

export const BotaoArredondado = ({ identifier, icone, texto, item = false, tipo = "" }) => {
    const { handleMatricula, setModalMaisInfo } = useAuth();
    const navigate = useNavigate();

    return <div
        className={tipo + " botao-arredondado " + identifier}
        onClick={() => {
            if (identifier == "inicio") return navigate("/browse");
            setModalMaisInfo(false);
            return /assistir/.test(identifier) ? handleMatricula(item, identifier) : setModalMaisInfo(item) }
        }
        children={<>
            {icone}
            <span children={texto} />
        </>}
    />
}
