import React from 'react';
import ReactDOM from 'react-dom/client';
import './sass/main.scss';
import { router } from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: "#20BA9F"
    },
    secondary: {
      main: "#e87c03"
    },
    custom: {
      branco: "#fff",
      branco_transparente: "rgba(250, 250, 250, .25)",
      branco_escuro: "#e5e5e5",
      cinza_claro: "#b3b3b3",
      cinza: "#8C8C8C",
      cinza_quase_escuro: "#808080",
      cinza_escuro: "#737373",
      preto_claro: "#333",
      preto_quase_claro: "#141414",
      preto: "#000",
      preto_transparente: "rgba(0,0,0, .75)"
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
