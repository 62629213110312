import { useEffect, useRef } from "react"
import Hls from "hls.js";

export const MediadeliveryPlayer = ({ conteudo, setPlayer, progresso }) => {
    const player = useRef();

    useEffect(() => {
        if (!Hls.isSupported()) return;

        var hls = new Hls({
            startPosition: progresso
        });
        
        hls.loadSource("https://vz-3c025ede-bdb.b-cdn.net/"+conteudo.hash_of_the_video+"/playlist.m3u8");
        hls.attachMedia(player.current);
        setPlayer(player);
        player.current.muted = false;
        hls.on(Hls.Events.MANIFEST_PARSED, (e, data) => {
            const levels = data.levels.filter(level => level.width <= window.outerWidth);
            const lastLevel = levels[levels.length - 1];
            hls.loadLevel = levels.indexOf(lastLevel);
        });
    }, [conteudo]);

    return <video ref={player} style={{ width: "100%" }} />
}