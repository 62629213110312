import { useNavigate } from "react-router";
import { BotaoArredondado } from "../components/BotaoArredondado";

export const ErrorPage = () => {
    return <div className="container-pagina-nao-encontrada">
        <div className="pagina-nao-encontrada">
            <h1 children="Você se perdeu?" />
            <p children="Infelizmente, não localizamos essa página. Você encontra muitos outros títulos na página inicial." />
            <BotaoArredondado
                tipo="branco"
                identifier="inicio"
                texto="Página inicial da Medflix"
            />
        </div>
    </div>
};

