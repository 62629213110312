import { Box, CircularProgress } from "@mui/material";
import { ReactComponent as Play } from "../images/svgs/play.svg";
import { ReactComponent as Plus } from "../images/svgs/plus.svg";
import { ReactComponent as Check } from "../images/svgs/check-mark.svg";
import { ReactComponent as Info } from "../images/svgs/info.svg";
import { BotaoRedondo } from "./BotaoRedondo";
import { BotoesDeAvaliacao } from "./BotoesDeAvaliacao";
import { useAuth } from "../hooks/useAuth";
import { useEffect, useState } from "react";

export const CardContent = ({ item, titulo, indiceDacoluna, tamanhoDoSlider }) => {
    const {  setModalMaisInfo, user, env } = useAuth();
    const [conteudo, setConteudo] = useState(item);
    const [iconeDeAvaliacao, setIconeDeAvaliacao] = useState(item?.interaction?.type);
    const [loading, setLoading] = useState(false);
    const [checkedEnrollment, setCheckedEnrollment] = useState(false);
    const [iconeDaMihaLista, setIconeDaMinhaLista] = useState(false);

    useEffect(() => {
        if (!conteudo) return;

        setIconeDaMinhaLista(conteudo?.enrollment && "check");
    }, [conteudo]);

    const handleMouseOver = () => {
        if (checkedEnrollment) return;
        setLoading(true);

        const headers = { 'x-access-token': user.access_token };
        fetch(env.nodeUrl + "enrollments?canvas_id=" + conteudo.canvas_id + "&user_id="+user.user.id, { headers })
            .then(resposta => resposta.json())
            .then(resposta => {
                const { enrollment } = resposta.metadados;
                if (enrollment) setConteudo({
                    ...conteudo,
                    enrollment
                });
                setLoading(false);
                setCheckedEnrollment(true);
            })
            .catch(e => setLoading(false));
    }

    const handleClick = () => {
        if (window.innerWidth > 767) return;
        setModalMaisInfo(conteudo);
    }

    return <Box className="card-of-the-content" onMouseOver={handleMouseOver} onClick={handleClick} key={conteudo.id}>
        <div
            className={[
                "pop-up-content",
                "tamanho-do-slider-" + tamanhoDoSlider,
                "coluna-" + indiceDacoluna
            ]
            .join(" ")}
        >
            <div
                className={`thumbnail`}
                style={{ backgroundImage: `url(${conteudo.thumbnail || conteudo.image_download_url})` }}
                children={
                    <div
                        className={"title-container" +  ' ' + (titulo == "Destacados" && "with-image")}
                        children={
                              titulo == "Destacados"
                                ? <img src={conteudo.custom_title} />
                                : <h2 children={conteudo.name.length < 60 ? conteudo.name : conteudo.name.substring(0, 60) + "..."} />
                        }
                    />
                }
            />
            <div className="info">
                <div className="interacoes">
                    <BotaoRedondo
                        identifier="assistir"
                        icone={<Play />}
                        item={conteudo}
                    />
                    {<BotaoRedondo
                        identifier="matricula"
                        texto={iconeDaMihaLista ? "Em Minha lista" : "Adicionar à Minha lista"}
                        icone={(loading && <CircularProgress thickness={1.7} />) || (iconeDaMihaLista ? <Check /> : <Plus />)}
                        item={conteudo}
                        setIconeDaMinhaLista={setIconeDaMinhaLista}
                    />}
                    <BotoesDeAvaliacao
                        iconeDeAvaliacao={iconeDeAvaliacao}
                        setIconeDeAvaliacao={setIconeDeAvaliacao}
                        item={conteudo}
                    />
                </div>
                <BotaoRedondo
                    identifier="mais-info"
                    texto="Páginas e informações"
                    icone={<Info />}
                    item={conteudo}
                />
            </div>
        </div>
    </Box>
}
