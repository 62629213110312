import { ReactComponent as Facebook } from "../images/svgs/facebook.svg";
import { ReactComponent as Instagram } from "../images/svgs/instagram.svg";
import { ReactComponent as Twitter } from "../images/svgs/twitter.svg";
import { ReactComponent as Youtube } from "../images/svgs/youtube.svg";

export const Footer = () => {
    return <footer>
        <div className="redes-sociais">
            <a href="https://www.facebook.com/FG.FGMED/" target="_blank"><Facebook /></a>
            <a href="https://www.instagram.com/fg_med/" target="_blank"><Instagram /></a>
            <a href="https://www.twitter.com/fgmedbr" target="_blank"><Twitter /></a>
            <a href="https://www.youtube.com/@fgmed" target="_blank"><Youtube /></a>
        </div>
        <div className="colunas">
            <div className="coluna">
                {/* <a href="">Avisos legais</a> */}
            </div>
            <div className="coluna">
                {/* <a href="">Central de Ajuda</a> */}
            </div>
            <div className="coluna">
                {/* <a href="">Termos de Uso</a> */}
            </div>
            <div className="coluna">
                {/* <a href="">Entre em contato</a> */}
            </div>
        </div>
    </footer>
}
