import { Document, Outline, Page, pdfjs  } from 'react-pdf';
import { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.js`;

export const PdfPlayer = ({
        conteudo,
        setLoading,
        popUps,
        popUpOutlinePages,
        setPopUpOutlinePages,
        setPopUpOutlinePagesTrigger,
        setNumPages,
        pageNumber,
        setPageNumber,
        innerHeight,
        setInnerHeight,
        progresso,
        salvaProgresso
    }) => {
    const [leftPosition, setLeftPosition] = useState(0);
    const [touchStart, setTouchStart] = useState(false);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const outlineRef = useRef();

    useEffect(() => {
        setLoading(false);
        window.addEventListener('resize', (e) => {
            setInnerWidth(e.target.innerWidth);
            setInnerHeight(e.target.innerHeight);
        });
    }, []);

    useEffect(() => {
        salvaProgresso(false, pageNumber);
    }, [pageNumber]);
    
    useEffect(() => {
        setPageNumber(progresso || 1);
    }, [progresso]);

    const onItemClick = ({ pageNumber: itemPageNumber }) => setPageNumber(itemPageNumber);

    const onDocumentLoadSuccess = (e) => {
        setNumPages(e.numPages)
    };

    const onTouchStart = (e) => {
      const [{ clientX }] = e.targetTouches;
      
      setTouchStart(clientX + leftPosition);
    }

    useEffect(() => {
        if (!popUps) setPopUpOutlinePages(false);
    }, [popUps])
    
    const onTouchMove = (e) => {
      const [{ clientX }] = e.targetTouches;

      const newLeftPosition = (touchStart - clientX);
      if (newLeftPosition <= -300) return;
      if (newLeftPosition > innerHeight - 400) return;

      setLeftPosition(newLeftPosition);
    }
  
    return <div style={{ position: "relative", left: -leftPosition }} onTouchMove={onTouchMove} onTouchStart={onTouchStart}>
        {conteudo ? <Document
            file={"https://fgmedflix.b-cdn.net/" + conteudo.name_of_the_file}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<div style={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
                <CircularProgress thickness={1.7} />
            </div>}
        >
            <Outline
                className={["pop-up-of-the-icon","outline-pdf", popUpOutlinePages?.show].filter(Boolean).join(" ") }
                ref={outlineRef}
                onItemClick={onItemClick}
                onLoadSuccess={(e) => {
                    setPopUpOutlinePagesTrigger(e?.length)
                }}
                onMouseOver={() => {
                    setPopUpOutlinePages({ show: "block" });
                }}
                onMouseLeave={() => {
                    setPopUpOutlinePages(false);
                }}
            />
            <Page
                height={innerHeight}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                pageNumber={pageNumber}
            />
        </Document> : ""}
    </div>
}
