const preparaParams = (queries = [], id, offset, limit, order = false) => {
    const inParams = [];
    const params = [];

    if (id) inParams.push(id);
    inParams.push(queries);
    if (offset >= 0 && limit) inParams.push([]); // fields
    if (offset >= 0) inParams.push(offset);
    if (limit) inParams.push(limit);
    if (order) {
        inParams.push(false);
        inParams.push(false);
        inParams.push(order);
    }
    params.push(inParams);

    return params;
}

const trataVariaveisEnv = () => {
    let { protocol, hostname, port  } = window.location;
    let key = "";
    let corshelper = "";
    port = port ? ":"+port : "";
    let nodeUrl = "";

    if (hostname == "localhost") {
        corshelper = "https://cors-anywhere.herokuapp.com/";
        key = "8";
        nodeUrl = "http://localhost:3001/";
    }
    else if (hostname == "fgmedflix.com") {
        key = "8";
        nodeUrl = "https://server.fgmedflix.com/";
    }
    else {
        key = "8";
        nodeUrl = "http://testnode.com/"
    }

    return {
      corshelper,
      protocol,
      hostname,
      port,
      key,
      nodeUrl
    }
  }

const funcoesAuxiliares = {
    preparaParams,
    trataVariaveisEnv
};

module.exports = funcoesAuxiliares;
